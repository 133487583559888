import React, { useEffect, useState } from "react";
import useAnalytics from "../../hooks/useAnalytics";
import PropTypes from "prop-types";
import EmptySpace from "../../components/Layout/EmptySpace";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import Cookie from "../../components/common/Cookie";
import redirectLogic from "../../helpers/redirect";
import YoutubeModal from "../../components/common/YoutubeModal";
import PricingModal from "../../components/Paywalls/Modal/PricingModal";
import { adsFixer } from "../../helpers/adsFixer";
import { isSavedUserInfo } from "../../helpers";
import ParamsController from "../../helpers/paramsController";
import SurveyButtonContainer from "../../containers/common/SurveyButtonContainer";
import HelpContainer from "../../containers/common/HelpContainer";
import Localization from "../../containers/localization";
import ReCAPTCHA from "react-google-recaptcha";
import { LayoutContext } from "../../store/context/layout-context";
import LoadingTimeController from "../../controllers/common/loadingTimeController";
import RecaptchaWrapper from "../../components/common/RecaptchaWrapper";
import "../../assets/scss/fonts/fonts.scss";
import "../../assets/scss/index.scss";
import "../../assets/scss/UI/index.scss";
import "../../assets/scss/Layout/LayoutDefault.scss";

const Layout = (props) => {
  const {
    addOnDefault,
    bgHeader,
    children,
    closePricing,
    closeYoutubeModal,
    cookieUnderForm = false,
    customClass,
    isAbsoluteHeader,
    isBundlePanel,
    isDisableRecaptcha,
    isDrPanel,
    isHiddenNavigation,
    isOpenPricing,
    isShowLanguageSwitcher,
    isShowSurveyButton,
    isShowYoutubeModal,
    isShowWebinarNativeBanner,
    isSimpleTopPanel,
    isWaMode,
    setLocale,
    tariffsElement,
    urlYoutubeModal,
  } = props;

  useEffect(() => {
    setIsSavedUser(isSavedUserInfo());
    redirectLogic();
    !isWaMode && checkDisclamer();
    adsFixer();
    const commonParams = new ParamsController();
    commonParams.handleIDev();
    new LoadingTimeController();
  }, []);

  useAnalytics(isWaMode);
  const [isShowCookie, setIsShowCookie] = useState(false);
  const [isShowTariffsModal, setIsShowTariffsModal] = useState(false);
  const [isSavedUser, setIsSavedUser] = useState(false);
  const [currentLocale, setCurrentLocale] = useState(null);
  const recaptchaRef = React.createRef();
  const [getCaptchaState, setCaptchaState] = useState();
  const [getCaptchaLoadingState, setCaptchaLoadingState] = useState(false);
  const [getCollectFormState, setCollectFormState] = useState(false);
  const [panelStatus, setPanelStatus] = useState(false);
  const [isModSurveyButton, setModSurveyButton] = useState(false);
  const [isShowFooter, setIsShowFooter] = useState(false);
  const [helpState, setHelpState] = useState({
    isFirstClick: false,
    isClick: false,
  });

  useEffect(() => {
    if (setLocale) {
      setLocale(currentLocale);
    }
  }, [currentLocale]);

  useEffect(() => {
    !!isOpenPricing && handlePricingMenu();
  }, [isOpenPricing]);

  useEffect(() => {
    return () => {
      recaptchaRef.current?.reset();
    };
  }, []);

  useEffect(() => {
    isDisableRecaptcha && checkCaptchaState();
  }, [getCaptchaLoadingState]);

  const updateHelpState = (args) => {
    setHelpState((prevState) => ({
      ...prevState,
      ...args,
    }));
  };

  const handlerClickHelp = () => {
    if (helpState.isFirstClick) {
      updateHelpState({ isClick: true });
    } else {
      updateHelpState({ isFirstClick: true });
    }
  }

  const checkCaptchaState = () => {
    if (localStorage.getItem("disableRecaptcha")) {
      setCaptchaState(true);
    }
  };
  const checkDisclamer = () => {
    const isDisclamer = localStorage.getItem("disclamer");
    setIsShowCookie(!isDisclamer);
  };

  const closeCookieAlert = () => {
    localStorage.setItem("disclamer", true);
    setIsShowCookie(false);
  };

  const changeSurveyButton = () => {
    setModSurveyButton(true);
  };
  const handlePricingMenu = () => {
    if (tariffsElement) {
      tariffsElement.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      openTariffsModal();
    }
  };

  const openTariffsModal = () => {
    // document.getElementsByTagName("html")[0].style.overflow = "hidden";
    setIsShowTariffsModal(true);
  };

  const closeTariffsModal = () => {
    // document.getElementsByTagName("html")[0].style.overflow = "auto";
    setIsShowTariffsModal(false);
    !!closePricing && closePricing();
  };

  return (
    <LayoutContext.Provider
      value={{
        captcha: recaptchaRef,
        token: getCaptchaState,
        injection: setCaptchaLoadingState,
        isInjected: getCaptchaLoadingState,
        pageFormState: getCollectFormState,
        updatePageFormState: setCollectFormState,
        panelStatus,
        setPanelStatus,
        isShowFooter,
        setIsShowFooter,
        helpState,
        updateHelpState,
        handlerClickHelp,
      }}
    >
      {getCaptchaLoadingState ? (
        <RecaptchaWrapper>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.RECAPTCHA_V2_KEY}
            onChange={() => setCaptchaState(true)}
          />
        </RecaptchaWrapper>
      ) : null}
      <div className="LayoutDefault" id="layout">
        <Header
          openPricingModal={handlePricingMenu}
          pricingRef={tariffsElement}
          bgHeader={bgHeader}
          isAbsoluteHeader={isAbsoluteHeader}
          isSavedUser={isSavedUser}
          isHiddenNavigation={isHiddenNavigation}
          isShowLanguageSwitcher={isShowLanguageSwitcher}
          isShowWebinarNativeBanner={isShowWebinarNativeBanner}
        />
        {!isHiddenNavigation ? (
          <EmptySpace isShowWebinarNativeBanner={isShowWebinarNativeBanner} />
        ) : null}
        {!isWaMode && isShowSurveyButton && (
          <SurveyButtonContainer isMod={isModSurveyButton} />
        )}
        {!isWaMode && (
          <HelpContainer
            topPanel={panelStatus && !isShowFooter && isSimpleTopPanel}
            topPanelDR={panelStatus && !isShowFooter && isDrPanel}
            topPanelBundle={panelStatus && !isShowFooter && isBundlePanel}
            changeSurveyButton={changeSurveyButton}
          />
        )}
        <main className={customClass || ""}>{children}</main>
        <Localization path="footer" setCurrentLocale={setCurrentLocale}>
          <Footer
            isSavedUser={isSavedUser}
            isHiddenNavigation={isHiddenNavigation}
          />
        </Localization>
        {isShowCookie ? (
          <Cookie
            close={closeCookieAlert}
            underForm={cookieUnderForm}
            topPanel={panelStatus && !isShowFooter && isSimpleTopPanel}
            topPanelDR={panelStatus && !isShowFooter && isDrPanel}
            topPanelBundle={panelStatus && !isShowFooter && isBundlePanel}
          />
        ) : null}
        {isShowYoutubeModal && urlYoutubeModal ? (
          <YoutubeModal
            closeYoutubeModal={closeYoutubeModal}
            url={urlYoutubeModal}
          />
        ) : null}
        {isShowTariffsModal ? (
          <PricingModal
            closeModal={closeTariffsModal}
            addOnDefault={addOnDefault}
          />
        ) : null}
      </div>
    </LayoutContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isShowYoutubeModal: PropTypes.bool,
  urlYoutubeModal: PropTypes.string,
  closeYoutubeModal: PropTypes.func,
  bgHeader: PropTypes.string,
  tariffsElement: PropTypes.object,
  customClass: PropTypes.string,
  setLocale: PropTypes.func,
  isShowFooterForm: PropTypes.bool,
  page: PropTypes.string,
  isHiddenNavigation: PropTypes.bool,
  isShowLanguageSwitcher: PropTypes.bool,
  isSimpleTopPanel: PropTypes.bool,
  isDrPanel: PropTypes.bool,
  isBundlePanel: PropTypes.bool,
};

export default Layout;
